<template>
  <div
    class="label-box flex"
    @click="
      goDetail(
        props.dynamicObj.class_type,
        props.dynamicObj.sale_script_content_id ||
          props.dynamicObj.task_content_id
      )
    "
  >
    <div class="left-icon">
      <img
        v-if="
          props.dynamicType == 'customer' && props.dynamicObj.dynamic_type === 2
        "
        :src="typeIcons[props.dynamicObj.dynamic_type]"
      />
      <img
        v-else-if="
          props.dynamicType == 'customer' && props.dynamicObj.dynamic_type != 2
        "
        :src="
          require(`../../../assets/img/customer-portrait/${props.dynamicObj.subject_type}.svg`)
        "
      />
      <img
        v-else
        :src="typeIcons[props.dynamicObj.dynamic_type == 2 ? 2 : 1]"
      />
    </div>
    <div class="label">
      <template v-for="contentData in content_arr" :key="contentData">
        <template v-if="typeof contentData === 'object'">
          <div class="content-box" v-if="contentData.key === 'content'">
            <div class="content">
              <div class="text">{{ contentData.data.value.content }}</div>
              <!-- 知识库 -->
              <show-media-list
                :media-list="contentData.data.value.file_arr"
                :limit-num="4"
              />
            </div>
          </div>
          <!-- 性别图片标签 -->
          <template
            v-if="
              contentData.key === 'customer_gender' ||
              contentData.key === 'share_customer_gender'
            "
          >
            <img
              v-if="contentData.data.value == 1 || contentData.data.value == 2"
              :src="sexIcons[contentData.data.value]"
              class="user-sex-icon"
            />
          </template>

          <!-- 类型 -->
          <template
            v-if="
              contentData.key === 'customer_type' ||
              contentData.key === 'share_customer_type'
            "
          >
            <span
              class="customer_type mlr5"
              :class="{
                wx: contentData.data.value == '@微信',
                qywx: contentData.data.value == '@企业微信'
              }"
            >
              {{ contentData.data.value }}
            </span>
          </template>
          <template v-if="contentData.key === 'tag_group_name'">
            <div
              :class="[
                'label-item',
                ['tag_mark_hq', 'unmark_hq', 'business_tag_mark_hq'].includes(
                  props.dynamicObj.class_type
                )
                  ? 'la-headquarters'
                  : 'la-service'
              ]"
            >
              <div class="label-title">
                {{ contentData.data.value.group_name }}
              </div>
              <div class="label-value">
                {{ contentData.data.value.tag_name }}
              </div>
            </div>
          </template>
          <template v-else-if="contentData.key === 'browse_time'">
            <span class="txt-blue fw500 lh18">
              {{ contentData.data.value }}
            </span>
          </template>
          <template v-else>
            <img
              :src="
                contentData.data.value == '' ? noImg : contentData.data.value
              "
              v-if="contentData.data.type === 'IMAGE'"
              class="content-img mr5"
            />
          </template>
        </template>
        <span v-if="typeof contentData === 'string'" class="string wb">
          {{ contentData }}
        </span>
      </template>
      <div class="time">{{ props.dynamicObj.create_time }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import ShowMediaList from './ShowMediaList.vue'
import { getStrArr } from './dynamic'

const sexIcons = ref({
  1: require('@/assets/img/common/man.png'),
  2: require('@/assets/img/common/women.png')
})
const typeIcons = ref({
  1: require('@/assets/img/community/group_peoples.svg'),
  2: require('@/assets/img/community/writing-fluently.svg')
})
const router = useRouter()
const route = useRoute()
const props = defineProps({
  dynamicObj: {
    type: Object,
    default: () => {}
  },
  dynamicType: {
    type: String,
    defualt() {
      return ''
    }
  }
})
const noImg = ref(
  'http://we-customer-api.freshgood.cn/upload/source_2022/01/26/fiRGqmzI8Dv4KbuSnTsNkOYQAL19VW7r.png'
)
const { content_arr } = getStrArr(props.dynamicObj)
// 前往详情页
const goDetail = (pageType, id) => {
  console.log('idididid', id)
  if (
    [
      'customer_touch_knowledge_group',
      'customer_touch_words_group',
      'customer_receive_words',
      'customer_receive_knowledge',
      'customer_touch_tag_group',
      'customer_receive_tag',
      'customer_touch_member_reply_group',
      'customer_receive_member_reply'
    ].includes(pageType)
  ) {
    // 知识库
    router.push({
      path: '/knowledge-detail',
      query: { id, type: 'dynamicList' }
    })
  }
  if (
    [
      'customer_touch_task_member_send_group',
      'customer_receive_task_member_send'
    ].includes(pageType)
  ) {
    // 任务
    router.push({
      path: '/marketing-task-detail',
      query: { task_id: id, type: 'dynamicList' }
    })
  }
}
</script>
<style lang="less" scoped>
.lh18 {
  line-height: 18px;
}
.label-box {
  padding: 0 0 8px;
  position: relative;
  margin-bottom: 8px;
  .left-icon {
    margin-right: 7px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 2px;
    height: 65%;
    background: #c8deff;
    border-radius: 1px;
  }
  > .label {
    // display: flex;
    width: 314px;
    background: #f7f8fa;
    border-radius: 4px;
    padding: 8px 12px;
    // flex-wrap: wrap;
    // align-items: center;
    .user-sex-icon {
      width: 14px;
      height: 14px;
      vertical-align: middle;
      margin: 0 4px;
    }
    .string {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #171717;
      line-height: 18px;
    }
    .text {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 600;
      text-align: left;
      color: #4a4a4a;
      line-height: 18px;
      margin: 2px 0 10px;
    }
    > .emphasize {
      font-weight: bold;
    }
    .time {
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(153, 153, 153, 0.8);
      line-height: 16px;
      flex-basis: 100%;
    }
    .customer_type {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Light;
      font-weight: 300;
      text-align: left;
      line-height: 18px;
    }
    .wx {
      color: #00c000;
    }
    .qywx {
      color: #ff6f00;
    }
  }
  .content-img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    vertical-align: middle;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }

  // 标签
  .label-item {
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 16px;
    align-items: center;
    font-size: 12px;
    border-radius: 2px;
    //   max-height: 54px;
    //   margin-right: 8px;
    //   margin-top: 9px;
    //   height: 100%;
    position: relative;

    .label-title {
      // flex: 1;
      padding: 2px 4px;
      max-width: 128px;
      // border-radius: 2px 0px 0px 2px;
      // white-space: nowrap;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .label-value {
      flex: 1;
      color: #fff;
      padding: 2px 8px;
      max-width: 136px;
      // position: relative;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  // 总部标签
  .la-headquarters {
    border: 1px solid #1773fa;
    .label-title {
      color: #1773fa;
      // border: 1px solid #1773fa;
    }
    .label-value {
      background-color: #1773fa;
      // border: 1px solid #1773fa;
    }
  }
  //服务标签
  .la-service {
    border: 1px solid #ff6f00;
    .label-title {
      color: #ff6f00;
      // border: 1px solid #ff6f00;
    }
    .label-value {
      background-color: #ff6f00;
      // border: 1px solid #ff6f00;
    }
  }
}
</style>
