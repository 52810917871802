import { $ajaxService } from './request'
import { formatDate } from '../utils/index'

// 主页的社群列表接口
export const getGroup = async ({ page, size, order, orderType, query }) => {
  const res = await $ajaxService.post($ajaxService.baseUrl + 'wk_group_list', {
    page,
    page_size: size,
    order,
    order_type: orderType,
    keyword: query
  })

  return Array.from(res.data.list, (item) => ({
    id: item.chat_id,
    name: item.name,
    imageURL: item.img,
    enterNum: item.join_group_num,
    leaveNum: item.sign_out_group_num,
    memberNum: item.group_members_num,
    waitAddNum: item.not_add_friends,
    createTime: item.create_time
  }))
}

// 客户群总预览
export const statisticsGroups = async () => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'wk_customer_group_preview'
  )

  return {
    enterNum: data.join_group_num,
    addNum: data.add_fridnds_num,
    groupTotal: data.total_group,
    customerTotal: data.total_customer,
    messageTotal: data.total_msg,
    addGroup: data.new_chat_cnt,
    addCustomer: data.new_member_cnt,
    sendMessage: data.chat_has_msg,
    lastTime: data.end_time
  }
}

// 筛选标签
export const getLabel = async () => {
  const { data } = await $ajaxService.get(
    $ajaxService.baseUrl + 'sort_properties',
    {
      type: 2
    }
  )

  let orderDef, orderTypeDef
  const order = [],
    orderType = []

  for (const item of data.order) {
    order.push({
      label: item.name,
      value: item.value
    })
    if (item.default) orderDef = item.value
  }

  for (const item of data.order_type) {
    orderType.push({
      label: item.name,
      value: item.id
    })
    if (item.default) orderTypeDef = item.id
  }

  return {
    orderDef,
    orderTypeDef,
    order,
    orderType
  }
}

// 客户群预览
export const statisticsGroup = async (id) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'wk_groupchat_info',
    {
      id
    }
  )

  const createTime = new Date(data.create_time * 1000)

  return {
    name: data.group_name,
    enterNum: data.today_add_nums,
    leaveNum: data.today_out_nums,
    waitAddNum: data.add_friends_num,
    customerTotal: data.group_members_num,
    createTime: formatDate(createTime, 'yyyy-MM-dd')
  }
}

// 统计标签组分布-根据客户群
export const statisticsLabelByGroup = async (id) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'guide_Label_distribution',
    {
      chat_id: id
    }
  )

  return Array.from(data, (group) => ({
    title: group.group_name,
    data: Array.from(group.data, (item) => ({
      name: item.name,
      value: item.value
    }))
  }))
}

// 群动态
export const getDynamic = async ({ id, page, size, query, labels, home }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'guide_group_customer_news',
    {
      chat_id: id,
      page,
      page_size: size,
      query, // 接口目前不支持
      follow_record_class_id: labels,
      marketing_suggestions: home ? 1 : undefined // 是否在群动态的首页上 - 营销类型
    }
  )

  return Array.from(data.list, (item) => ({
    type: item.type,
    value: item.content,
    createTime: item.create_time
  }))
}

// v0.7.3群基本信息
export const getGroupContent = async ({ id }) => {
  const data = await $ajaxService.post(
    $ajaxService.baseUrl + 'shoppers/group_detail',
    {
      qw_chat_id: id
    }
  )
  return data
}

// v0.7.3群人员增长图
export const getEchartData = async ({ id, start_time, end_time }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'shoppers/group_info',
    {
      qw_chat_id: id,
      start_time,
      end_time
    }
  )
  return data
}

// v0.7.3群标签饼图
export const getEchartPieData = async ({ id }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'shoppers/group_tags_list',
    {
      qw_chat_id: id
    }
  )
  return data
}

// v0.7.3添加动态
export const addGroupDynamic = async ({ chat_id, content }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'extensive_customer/add_group_dynamic',
    {
      chat_id,
      content
    }
  )
  return data
}

// v0.7.2.5群动态
export const getDynamicList = async ({ page, size, chat_id }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'extensive_customer/group_dynamic_list',
    {
      page,
      limit: size,
      chat_id
    }
  )
  return data
}

// 群动态-最近动态
export const getDynamicByHome = async ({ id, page, size }) => {
  return await getDynamic({
    id,
    page,
    size,
    home: true
  })
}

// 获取动态分类信息
export const getDynamicTypes = async () => {
  const { data } = await $ajaxService.get(
    $ajaxService.baseUrl + 'guide_group_get_tree_class'
  )

  const _All = () => ({
    id: '*',
    name: '全部'
  })

  const _normal = (data) => {
    if (Array.isArray(data)) return Array.from(data, _normal)

    const result = {
      id: data.id,
      name: data.name
    }
    if (data.children) {
      const children = _normal(data.children)
      children.unshift(_All())
      Object.assign(result, {
        children
      })
    }

    return result
  }

  return [
    Object.assign(_All(), {
      children: [_All()]
    })
  ].concat(_normal(data))
}

// 群成员
export const getCustomerByGroup = async ({ id, page, size }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'wk_group_customer_list',
    {
      chat_id: id,
      page,
      page_size: size
    }
  )

  return Array.from(data.list, (item) => {
    const _otherInfo = item.other_info

    return {
      id: item.id,
      externalID: item.external_userid,
      name: item.name,
      type: item.customer_type,
      company: item.corp_name,
      avatar: item.img,
      addTime: item.add_time,
      otherInfo: _otherInfo && _otherInfo.name + '：' + _otherInfo.value,
      label: item.label
    }
  })
}
