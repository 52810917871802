import { computed, reactive, toRefs } from 'vue'
export const getStrArr = (dynamicObj) => {
  let reg = /\((.*?)\)/g
  let matchReg = /\(([^)]*)\)/
  let data = reactive({
    content_arr: []
  })
  data.content_arr = computed(() => {
    let temp = []
    let matchContentArr = []
    let rcontent_text = dynamicObj.content_text
    while ((temp = reg.exec(dynamicObj.content_text))) {
      let res = temp[0].match(matchReg)
      if (dynamicObj.content[res[1]]) {
        rcontent_text = rcontent_text.replace(res[0], '$')
        matchContentArr.push({
          key: res[1],
          data: dynamicObj.content[res[1]]
        })
      }
    }
    return _strToarr(rcontent_text, '$', matchContentArr)
  })

  const _strToarr = (rcontent_text, query, dataArr) => {
    let str = rcontent_text
    let strArr = str.split(query)
    let index = str.indexOf(query)
    let num = -1
    while (index !== -1) {
      num++
      if (!strArr[num]) {
        strArr.splice(num, 1, dataArr.shift())
      } else {
        strArr.splice(num + 1, 0, dataArr.shift())
        num++
      }
      index = str.indexOf(query, index + 1)
    }
    const filterArr = strArr.filter((item) => item != '')
    return filterArr
  }
  return {
    ...toRefs(data)
  }
}
