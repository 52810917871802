<template>
  <div v-if="mediaShowList.length" :class="['media-box', '']">
    <div class="media-item" v-for="mediaItem in mediaShowList" :key="mediaItem">
      <van-image
        v-if="mediaItem.type !== 'VIDEO'"
        width="48px"
        height="48px"
        fit="fill"
        :src="showImgCover(mediaItem)"
      />
      <video
        v-else
        class="cover-img"
        preload="metadata"
        object-fit="contain"
        :controls="false"
        :src="`${mediaItem.file_url}#t=0.1`"
      />
      <!-- <video
        class="cover-img"
        v-else
        fit="fill"
        :src="mediaItem.file_url"
      ></video> -->
      <img
        v-if="['VIDEO', 'MINIPROGRAM', 'LINK'].includes(mediaItem.type)"
        class="play-icon"
        :src="require('@/assets/img/quickSend/' + mediaItem.type + '.svg')"
        alt="paly"
      />
      <div
        v-if="['LINK', 'MINIPROGRAM', 'FILE'].includes(mediaItem.type)"
        class="media-item-title"
      >
        {{
          mediaItem.title ||
          mediaItem.qw_link_title ||
          mediaItem.qw_miniprogram_title ||
          mediaItem.file_name
        }}
      </div>
    </div>
    <div v-if="maxMedia" class="over-box">
      ...共
      <span class="txt-blue">{{ mediaItemList.length }}</span>
      个
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import { formatTaskItem } from '@/utils/sendMessage'

export default {
  name: 'ShowzMediaList',
  components: {
    [VanImage.name]: VanImage
  },
  props: {
    mediaList: {
      // 媒体数据
      type: Array,
      default() {
        return []
      }
    },
    limitNum: {
      // 限制媒体数量
      type: Number,
      default() {
        return 5
      }
    }
  },
  data() {
    return {
      maxMedia: false,
      mediaShowList: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init() {
      let arr = JSON.parse(JSON.stringify(this.mediaList))
      this.mediaItemList = arr
      this.limitMadie()
    },
    // 限制媒体数量
    limitMadie() {
      // 深拷贝
      this.mediaShowList = JSON.parse(JSON.stringify(this.mediaList))

      if (this.mediaShowList.length > this.limitNum) {
        this.maxMedia = true
        this.mediaShowList.splice(
          this.limitNum,
          this.mediaShowList.length - this.limitNum
        )
      } else {
        this.maxMedia = false
      }
      let arr = JSON.parse(JSON.stringify(this.mediaShowList))
      this.mediaShowList = formatTaskItem(arr)
    },
    //封面图片过滤
    showImgCover(item) {
      let stauffe = ''
      //   console.log('item', item)
      if (item.file_name) {
        stauffe = item.file_name.split('.')[1]
      }
      // console.log('附件封面', url, type, file_suffix, item)
      if (!item.file_url && item.type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      } else if (!item.file_url && item.type !== 'LINK') {
        return ''
      }
      if (item.type == 'FILE' && ['doc', 'docx'].includes(stauffe)) {
        return require('../../../assets/img/quickSend/word.svg')
      } else if (item.type == 'FILE' && ['pdf'].includes(stauffe)) {
        return require('../../../assets/img/quickSend/pdf.svg')
      } else if (item.type == 'FILE' && ['xlsx', 'xls'].includes(stauffe)) {
        return require('../../../assets/img/quickSend/excel.svg')
      } else if (item.type == 'FILE' && ['pptx', 'ppt'].includes(stauffe)) {
        return require('../../../assets/img/quickSend/ppt.svg')
      }
      let baseUrl = item.file_url.indexOf('http') === -1 ? 'https://' : ''
      console.log('附件封面', item.file_url)
      return baseUrl + item.file_url
    }
  }
}
</script>
<style scoped lang="less">
.media-box {
  display: flex;
  // flex-wrap: wrap;
  // max-height: ;
  // margin-top: 12px;
  .media-item {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
  }

  .cover-img {
    width: 48px;
    height: 48px;
    border: 1px solid #f5f5f5;
  }

  .play-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    z-index: 5;
  }

  .over-box {
    font-size: 14px;
    color: #4a4a4a;
    display: flex;
    align-items: flex-end;
  }

  .media-item-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    text-align: center;
    padding: 0 5px;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>
