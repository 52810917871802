<template>
  <!-- 列表数据 -->
  <div v-if="id" class="dynamic-logs">
    <van-list
      v-model:loading="loading"
      v-model:error="error"
      :finished="finished"
      :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
      error-text="请求失败，点击重新加载"
      @load="onNext"
    >
      <!-- 单个记录 -->
      <div v-for="(item, idx) in list" :key="idx" class="logs-item">
        <div class="item-date">{{ item.time }}</div>
        <template v-for="dynamic in item.list" :key="dynamic.id">
          <dynamic-list :dynamic-obj="dynamic" />
        </template>
        <div class="create-time" v-if="item.createTime">
          {{ item.createTime }}
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { watch, ref } from 'vue'
import { Search, Button, List, Notify, Toast } from 'vant'
import { pagination } from '../../utils/pagination'
import { getDynamicList } from '../../service/group.service'
import { getContext, getCurExternalChat } from '../../utils/nwechat'
import { useRoute } from 'vue-router'
import DynamicList from './components/DynamicList.vue'

export default {
  components: {
    DynamicList,
    [Search.name]: Search,
    [Button.name]: Button,
    [List.name]: List
  },
  setup() {
    const {
      query: { id }
    } = useRoute()
    const params = ref({
      chat_id: id
    })
    const {
      list,
      params: paramsObj,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getDynamicList, { params, pageMode: true })
    watch(paramsObj, async () => {
      list.value = []
      loading.value = true
      Toast.loading({ message: '加载中...', forbidClick: true })
      if (await onRefresh()) {
        Toast.clear()
        loading.value = false
      }
    })
    return {
      list,
      paramsObj,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    }
  },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.initId()
  },
  methods: {
    // 初始化当前群ID
    async initId() {
      const id = this.$route.query.id
      console.log('initId', id)
      if (id) {
        this.id = id
        this.paramsObj.id = id
        return
      }

      try {
        const entry = await getContext()
        if (entry === 'group_chat_tools') {
          // 群聊
          this.id = await getCurExternalChat()
          this.paramsObj.id = this.id // 最近分享的搜索条件
        } else {
          Notify({ type: 'warning', message: '请从群里工具栏进入！' })
        }
      } catch (e) {
        Notify({ type: 'danger', message: '群ID获取失败，请重新进入！' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.dynamic-control {
  display: flex;
  align-items: stretch;
}

.dynamic-logs {
  background: @white;
  border-radius: 4px;
  font-size: 14px;
  height: 100%;
  padding-top: 18px;
  .logs-item {
    color: @gray-6;
    background-color: @white;
    .label-box {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .item-date {
      width: 90px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: #cecece;
      border-radius: 4px;
      margin: 0 auto 12px;
    }
  }
}
</style>
